<script setup lang="ts">
const openModal = ref(false);

onMounted(() => {
  addEventListener("triggerModal", (event) => {
    openModal.value = (
      event as CustomEvent<{ openModal: boolean }>
    ).detail.openModal;
  });
});
</script>

<template>
  <ClientOnly>
    <Teleport to="body">
      <div
        class="md:hidden w-350px flex-col bg-scheppach-primary-50 fixed z-1000 h-dvh overflow-y-scroll overscroll-contain top-0 bottom-0 isolate pb-20"
        :class="[openModal ? 'flex' : 'hidden']"
      >
        <div class="flex justify-between px-5 py-6">
          <div class="c-scheppach-primary-500 font-extrabold uppercase">
            {{ $t("listing.filter.filter") }}
          </div>
          <div
            class="i-sl-close size-6 c-scheppach-primary-500 hover:c-scheppach-error-500 duration-350 hover:rotate--90 cursor-pointer"
            @click="openModal = false"
          ></div>
        </div>

        <div id="mobile-filter-modals" class="flex-col">
          <!-- Placeholder Container -->
        </div>

        <div
          class="fixed flex justify-center z-1 bottom-0 left-0 right-0 top-auto bg-scheppach-primary-50 py-6 px-5"
        >
          <button
            class="bg-scheppach-primary-500 c-scheppach-shades-0 rounded p-3 mx-auto"
            type="button"
            @click="openModal = false"
          >
            {{ $t("listing.filter.showResults") }}
          </button>
        </div>
      </div>
    </Teleport>
  </ClientOnly>
</template>
